import React, {Component} from "react";
import {Link} from "react-router-dom";
import DateTools from "../../components/utils/DateTools";
import dayjs from "dayjs";

class ProcedureAccess extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let proceduresEncours = []
        let proceduresAutres = []
        let proceduresTerminees = []

        if (this.props.procedures) {

            let procedures = this.props.procedures.proceduresTerminees
            for (const p in procedures) {
                let pathname = p.toLowerCase()
                let nom = p
                if (p === 'Reprise') {
                    pathname = 'repriseAbandon'
                    nom = 'Reprise par abandon'
                } else if (p === 'RepriseNR') {
                    pathname = 'repriseNonRenouvellement'
                    nom = 'Reprise pour non renouvellement'
                } else if (p === 'Retrocession') {
                    nom = 'Rétrocession'
                }
                for (const procedure in procedures[p]) {
                    proceduresTerminees.push(
                        <div className="d-flex border-bottom-h green-tick" key={pathname}>
                            {
                                <Link to={{
                                    pathname: "/" + pathname + "/" + this.props.emplacement.id,
                                }} className="edit-tbl">
                                    [{dayjs(procedures[p][procedure]['date_debut']).format('DD/MM/YYYY')}] {p}
                                </Link>
                            }
                        </div>
                    )
                }
            }


            procedures = this.props.procedures.proceduresEncours
            for (const p in procedures) {
                let pathname = p.toLowerCase()
                let nom = p
                if (p === 'Reprise') {
                    pathname = 'repriseAbandon'
                    nom = 'Reprise par abandon'
                } else if (p === 'RepriseNR') {
                    pathname = 'repriseNonRenouvellement'
                    nom = 'Reprise pour non renouvellement'
                } else if (p === 'Retrocession') {
                    nom = 'Rétrocession'
                }
                proceduresEncours.push(
                    <div className="d-flex border-bottom-h green-tick" key={pathname}>
                        {
                            this.props.emplacement && !this.props.location.isArchived ?
                                <Link to={{
                                    pathname: "/" + pathname + "/" + this.props.emplacement.id,
                                }} className="col-sm-4 pl-0 soleilRegular-fonts black text-decoration-none">{nom}</Link> : ''
                        }
                        <div className="col-sm-8 pl-0 soleilBold-fonts">Commencée le {DateTools.formatDate(procedures[p]['dateDebut'])}</div>
                    </div>
                )
            }

            procedures = this.props.procedures.proceduresAutres
            for (const p in procedures) {
                let pathname = p.toLowerCase()
                let nom = p
                if (p === 'Reprise') {
                    pathname = 'repriseAbandon'
                    nom = 'Reprise par abandon'
                } else if (p === 'RepriseNR') {
                    pathname = 'repriseNonRenouvellement'
                    nom = 'Reprise pour non renouvellement'
                } else if (p === 'Retrocession') {
                    nom = 'Rétrocession'
                }
                proceduresAutres.push(
                    <div className={"d-flex border-bottom-h " + (procedures[p]['possible'] ? 'green-tick' : 'red-tick') } key={pathname}>
                        {
                            this.props.emplacement && !this.props.location.isArchived  ?
                                procedures[p]['possible'] ?
                                <Link to={{
                                    pathname: "/" + pathname + "/" + this.props.emplacement.id,
                                }} className="col-sm-4 pl-0 soleilRegular-fonts black text-decoration-none">{nom}</Link>
                                    : <p className="col-sm-4 pl-0 soleilRegular-fonts black" >{nom}</p>
                                : ''
                        }
                        <div className="col-sm-8 pl-0 soleilBold-fonts">{procedures[p]['texte']}</div>
                    </div>
                )
            }
        }

        return (
            <div className="procedures-acces-wrapper">
                {
                    proceduresEncours.length > 0 ?
                        <div className="title soleilRegular-fonts mt-1">ASSISTANCE RÉGLEMENTAIRE : PROCÉDURES EN
                            COURS
                        </div>
                        :''
                }
                {proceduresEncours}
                <div className="title soleilRegular-fonts mt-4">ASSISTANCE RÉGLEMENTAIRE : AUTRES
                    PROCÉDURES
                </div>
                {proceduresAutres}
                {
                    proceduresTerminees.length > 0 ?
                        <>
                        <div className="title soleilRegular-fonts mt-4">ASSISTANCE RÉGLEMENTAIRE : PROCÉDURES TERMINÉES
                        </div>
                            <div style={{
                                'overflow': 'scroll',
                                'max-height': '200px'
                            }}>
                                {proceduresTerminees}
                            </div>
                        </>
                        : <></>
                }
            </div>
        )
    }
}

export default ProcedureAccess;