import React, {Component} from 'react';
import btnPlusIcon from '../../assets/images/btn-plus-icon.svg';
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import filterIconSubmenu from '../../assets/images/filter-icon-submenu.svg';
import {Link} from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import OrderBy from "../../components/utils/OrderBy";
import GetPDF from "../../components/utils/GetPDF";
import GetListePDF from "../../components/utils/GetListePDF";

class EmplacementListe extends Component {

    constructor(props) {
        super(props);
        this.paginationRef = React.createRef();
        this.state = {
            selected    : 0,
            emplacements: [],
            total       : 0,
            orderBy     : null,
            sortBy      : null,
            selectedId  : [],
            search      : null,
            typeExt     : 'pdf'
        }
    }

    refreshPagination () {
        this.paginationRef.current.changePage(1)
    }

    updateState = (res) => {
        this.setState({
            emplacements    : res.data.emplacements,
            total           : res.data.total,
            locationsActives: res.data.locations
        })
    }

    handleCheck = (e) => {
        let parent     = e.target.parentElement.parentElement
        let selectedId = this.state.selectedId
        let number     = this.state.selected

        if (e.target.checked === true) {
            selectedId.push(parent.getAttribute('data-key'))
            number+=1
        } else {
            const index = selectedId.indexOf(parent.getAttribute('data-key'));
            if (index > -1) {
                selectedId.splice(index, 1);
                number-=1
            }
        }
        this.setState({
            selected  : number,
            selectedId: selectedId
        });
    }

    sort = (sortBy) => {
        if (this.state.sortBy === sortBy) {
            this.setState(prevState =>{
                return{
                    ...prevState,
                    orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
                }
            })
        } else {
            this.setState({
                sortBy : sortBy,
                orderBy: 'asc'
            })
        }
    }

    render() {
        let tableRows = this.state.emplacements.map((em) => {
            let emplacementId = em.id
            let champsSuppLocation = false
            let location = false
            let estLibre = em.libre
            if (this.state.locationsActives.hasOwnProperty(emplacementId)) {
                location = this.state.locationsActives[emplacementId]
                champsSuppLocation = this.state.locationsActives[emplacementId]['champsSupp']
                estLibre = false
                if (location['type'] === 'Dispersion pleine nature') {
                    return null
                }
            }

            let numeroConcession = em.numero1 ?? '' + '-' + em.numero2 ?? '' + '-' +em.numero3 ?? '' + '-' +em.numero4 ?? ''
            numeroConcession = numeroConcession.replaceAll('-0', '').replaceAll('--', '')

            return(
            <tr key={em.id} data-key={em.id} className={this.state.selectedId.includes(em.id.toString()) ? 'liste-border' : 'liste-border-none'}>
                <td>
                    <input type="checkbox" className="table-checkbox" onChange={this.handleCheck} checked={this.state.selectedId.includes(em.id.toString())}/>
                    {em.fullNameLogicim }
                </td>
                <td>
                    {em.numero1 ? <span className="family_no">{numeroConcession}</span> : <td>-</td> }
                </td>
                <td>
                    { champsSuppLocation && champsSuppLocation['famille'] ? champsSuppLocation['famille'] : '-' }
                </td>
                <td>{ estLibre && location ? '-' : location['type'] ? location['type'] : '-' }</td>
                <td>{em.surface ? em.surface + 'm²' : '-'}</td>
                <td>
                    {estLibre ? <span className="tbl bk-green">OUI</span>  : <span className="tbl no bk-red">NON</span> }
                </td>
                <td>
                    <Link to={{
                        pathname: "/fiche-concession/" + em.id,
                    }} className={"edit-tbl emplacement " + (estLibre ? 'estlibre' : '')} >
                        consulter
                    </Link>
                </td>
                <td>
                    <p className="exporter-tbl cursor" onClick={() => GetPDF('emplacement', em.id)}>
                        <img src={pdfIconTbl} alt="" className="img-fluid" />
                        exporter
                    </p>
                </td>
            </tr>
            )
        })

        return (
            <div>
                <div className="mx-3">
                    <div className="table-responsive px-3 scroll listes-leftpanel" id="listes-leftpanel-emplacement">
                        <table className="table table-striped table-borderless" id="sortTable">
                            <thead>
                            <tr>
                                <th className="sortStyle">
                                    Carré / Allée / Num
                                    <span onClick={() => this.sort('allee')}>
                                        <OrderBy isClicked={this.state.sortBy === 'allee'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    N°
                                </th>
                                <th className="sortStyle">
                                    Famille
                                    <span onClick={() => this.sort('famille')}>
                                        <OrderBy isClicked={this.state.sortBy === 'famille'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Type
                                </th>
                                <th className="sortStyle">
                                    Surface
                                    <span onClick={() => this.sort('surface')}>
                                        <OrderBy isClicked={this.state.sortBy === 'surface'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">Libre</th>
                                <th colSpan="2" className="sortStyle">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="container-fluid py-3 px-lg-5">
                    <div className="row d-flex justify-content-between">
                        <div className="text-center text-sm-left">
                            <button type="button" className="add-new-location-btn d-none">
                                Ajouter nouveau
                                <img src={btnPlusIcon} alt="+" className="img-fluid ml-2"/>
                            </button>
                        </div>
                        <Pagination
                            ref         = {this.paginationRef}
                            url         = '/emplacements/locationsActivesEmplacements/'
                            sort        = {this.state.sortBy}
                            order       = {this.state.orderBy}
                            filters     = {this.props.filters}
                            eSpaceId    = {localStorage.getItem('espace_id')}
                            type        = {null}
                            updateState = {this.updateState}
                            search      = {this.state.search}
                        />
                        <div
                            className="list-button d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
                            <div>
                            <input type="search" onKeyUp={(e) => {this.paginationRef.current.changePage(1); this.setState((prevState) => {
                                return {
                                    ...prevState,
                                    search: e.target.value
                                }
                            })}} placeholder="Rechercher dans cette liste" className={"form-control"} />
                            </div>
                            <div>
                                <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('emplacement')}>
                                    <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER LISTE <br/> EMPLACEMENTS</span>
                                </button>
                                <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('emplacement', null, 'csv')}>
                                    <img src={filterIconSubmenu} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER EXCEL <br/> EMPLACEMENTS</span>
                                </button>
                            </div>
                            <div>

                                <select name="" className="form-control mb-2" id="" value={this.state.typeExt} onChange={(e) => {
                                    this.state.typeExt = e.target.value
                                }}>
                                    <option value="pdf">PDF</option>
                                    <option value="csv">Excel</option>
                                </select>
                            <button type="button" className="listing-complete-btn soleilBold-fonts cursor" onClick={() => GetListePDF('emplacement',  this.state.selectedId, this.state.typeExt)}>
                                Exporter la sélection
                                <span className="slt-nb">{ this.state.selected }</span></button>
                            </div>
                            <button type="button" className="listing-complete-btn soleilBold-fonts">Liste filtrée ( {this.state.total}
                                )
                            </button>
                            <button type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (&nbsp;
                                {this.state.total} )
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmplacementListe;