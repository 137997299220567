import React, {Component} from "react";
import axios from "../../helpers/axios";

class SearchEmplacement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recherche: '',
            resultats: [],
            show     : false,
            libelle  : this.props.libelle ? this.props.libelle : 'All'
        }
    }

    getSearchData(value) {
        return {
            from : 0,
            size : 100,
            query : {
                bool : {
                    ... (this.state.libelle !== 'All'? {
                        must: {
                            match: {etatLibelle: this.state.libelle}
                        }
                    } : {}),
                    should: [
                        {
                            match_phrase_prefix : {
                                fullNameLogicim : {
                                    query: value
                                }
                            }
                        },
                        {
                            match_phrase_prefix : {
                                typeLocation : {
                                    query : value
                                }
                            }
                        },
                        {
                            match_phrase_prefix : {
                                famille : {
                                    query : value
                                }
                            }
                        },
                        {
                            match_phrase_prefix : {
                                numeroLocation : {
                                    query : value
                                }
                            }
                        }
                    ],
                    minimum_should_match : 1
                }
            }
        }
    }

    handleRecherche = (e) => {
        this.setState({
            recherche : e.target.value,
            show : true
        })
        if (e.target.value) {

            axios.get(process.env.REACT_APP_ES + 'logicim_' + localStorage.getItem('espace_id') + '_emplacements/_search', {
                withCredentials: false,
                params : {
                    source : JSON.stringify(this.getSearchData(e.target.value)),
                    source_content_type : 'application/json',
                }
            }).then((res) => {
                this.setState({
                    resultats : res.data.hits.hits
                })
            })
        }
    }

    updateFieldForm = (id, emplacement) => {
        const champsExhumForm = document.getElementById(this.props.hiddenField)
        const champsNomExhumForm = document.getElementById('exhumation_numeroEmplacement')
        if (champsExhumForm) {
            champsExhumForm.value = id
        }
        if (champsNomExhumForm) {
            champsNomExhumForm.value = emplacement
        }
        const updateId = this.props.updateId
        if(updateId) {
            updateId(id)
        }
        this.setState({
            show      : false,
            recherche : emplacement
        })
    }

    render() {
        const resultats = this.state.resultats.map((r => {
            const objet = r['_source']
            return (
                <li className="autocomplete-choice" key={objet.id} onClick={() => this.updateFieldForm(objet.id, objet.fullNameLogicim)}>
                    <p>{objet.fullNameLogicim} {objet.famille ? '(' + objet.famille+')' : ''}</p>
                    <span>{objet.typeLocation ? objet.typeLocation + ( objet.numeroLocation ? ' n° ' + objet.numeroLocation : '') : 'Emplacement vide'}</span>
                </li>
            )
        }))
        return (
            <div className={"champs-step mt-1 " + this.props.class} id="reinhumation-recherche">
                <div className="d-flex ">
                    <label htmlFor={this.props.id} className="p-0 soleilRegular-fonts mr-1 mb-0">Choisir l'emplacement :</label>
                    <div className="d-flex flex-column">
                        <input type="text" id={this.props.id} name={this.props.id}
                               className="soleilRegular-fonts px-1"
                               placeholder="Rechercher dans mon cimetière"
                               value={this.state.recherche}
                               onChange={this.handleRecherche}
                        />
                        {
                            this.state.show && this.state.resultats.length > 0?
                                <ul className="resultats autocomplete-liste p-0 m-0 position-fixed">
                                    {resultats}
                                </ul>
                                :''
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchEmplacement;