import React, {Component, useState} from 'react';
import DateTools from "../../components/utils/DateTools";
import pdfIconTbl from '../../assets/images/pdf-icon-tbl.svg';
import filterIconSubmenu from '../../assets/images/filter-icon-submenu.svg';
import Pagination from "../../components/pagination/Pagination";
import {Link} from "react-router-dom";
import AjoutNouveau from "../../components/concession/AjoutNouveau";
import OrderBy from "../../components/utils/OrderBy";
import GetPDF from "../../components/utils/GetPDF";
import GetListePDF from "../../components/utils/GetListePDF";

class ConcessionsListe extends Component {

    constructor(props) {
        super(props);
        this.paginationRef = React.createRef();
        this.state = {
            selected   : 0,
            concessions: [],
            total      : 0,
            orderBy    : null,
            sortBy     : null,
            selectedId : [],
            filters    : [],
            search     : null,
            typeExt    : 'pdf'
        }
    }

    updateState = (res) => {
        this.setState({
            concessions: res.data.data,
            total      : res.data.total,
        })
    }

    handleCheck = (e) => {
        let parent     = e.target.parentElement.parentElement
        let selectedId = this.state.selectedId
        let number     = this.state.selected

        if (e.target.checked === true) {
            selectedId.push(parent.getAttribute('data-key'))
            number+=1
        } else {
            const index = selectedId.indexOf(parent.getAttribute('data-key'));
            if (index > -1) {
                selectedId.splice(index, 1);
                number-=1
            }
        }
        this.setState({
            selected  : number,
            selectedId: selectedId
        });
    }

    sort = (sortBy) => {
        if (this.state.sortBy === sortBy) {
            this.setState(prevState =>{
                return{
                    ...prevState,
                    orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
                }
            })
        } else {
            this.setState({
                sortBy : sortBy,
                orderBy: 'asc'
            })
        }
    }

    refreshPagination () {
        this.paginationRef.current.changePage(1)
    }

    render() {
        const tableRows = this.state.concessions.map((c) => {
            let numeroConcession = c.emplacement.numero1 ?? '' + '-' + c.emplacement.numero2 ?? '' + '-' +c.emplacement.numero3 ?? '' + '-' +c.emplacement.numero4 ?? ''
            numeroConcession = numeroConcession.replaceAll('-0', '').replaceAll('--', '')
            const etat = DateTools.verifDateFin(c.dateFin)
            return (
                <tr key={c.id} data-key={c.id} className={this.state.selectedId.includes(c.id.toString()) ? 'liste-border' : 'liste-border-none'}>
                    <td>
                        <input type="checkbox" className="table-checkbox" onChange={this.handleCheck} checked={this.state.selectedId.includes(c.id.toString())}/>
                        {c.emplacement.fullNameLogicim}
                    </td>
                    <td>
                        <span className="family_no">
                            { c.numero ? c.numero : "-" }
                        </span>
                    </td>
                    <td>{c.famille}</td>
                    <td className="black">{c.typeConcession ? c.typeConcession : '-' }</td>
                    <td className="black">{ c.perpetuelle == 1 ? 'perpétuelle' : ''}
                        {c.duree && c.perpetuelle != 1?
                             c.duree >= 99 ? 'perpétuelle' :
                            c.duree + ' ans' : ''}</td>
                    <td className={c.dateFin && etat === 'Échue' ? 'red' : c.dateFin ? 'green' :''}>
                        {c.dateFin && c.duree < 99 && c.perpetuelle != 1 ? DateTools.formatDate(c.dateFin) : '-'}
                    </td>
                    <td>
                        {c.dateFin ?
                            <span className={
                                etat === 'Échue' ? 'tbl bk-red text-uppercase' : etat === 'Non defini' ? 'tbl bk-grey text-uppercase' : 'tbl bk-green text-uppercase'
                            }>{etat}</span>
                            :''
                        }
                        {!c.dateFin && c.perpetuelle ?
                            <span className={'tbl bk-green text-uppercase'}
                            >en cours</span>
                            :''
                        }
                        {
                            ['Jardin du souvenir', 'Ossuaire'].includes(c.type) ?
                                <span className='tbl bk-green text-uppercase'>en cours</span>
                                :''
                        }
                    </td>
                    <td>
                        {
                            c.nbPlaces ?
                                c.nbPlacesOccupees + '/' + c.nbPlaces
                            : '-'
                         }
                        {
                            c.nbPlaces && c.emplacement.occupants.length >= c.nbPlaces ?
                                <span className="listing-incomplete-btn soleilBold-fonts">Pleine</span>
                                : ''
                        }
                    </td>
                    <td>
                        <Link to={{
                            pathname: "/fiche-concession/" + c.emplacement.id,
                        }} className="edit-tbl concession">
                            consulter
                        </Link>
                    </td>
                    <td>
                        <p className="exporter-tbl cursor" onClick={() => GetPDF('location', c.id)}>
                            <img src={pdfIconTbl} alt="" className="img-fluid" />
                            exporter
                        </p>
                    </td>
                </tr>
            )
        })

        return(
            <div>
                <div className="mx-3">
                    <div className="table-responsive px-3 scroll listes-leftpanel" id="listes-leftpanel-concessions">
                        <table className="table table-striped table-borderless">
                            <thead>
                            <tr>
                                <th className="sortStyle">
                                    Carré / Allée / Num
                                    <span onClick={() => this.sort('allee')}>
                                        <OrderBy isClicked={this.state.sortBy === 'allee'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">N°</th>
                                <th className="sortStyle">
                                    Famille
                                    <span onClick={() => this.sort('famille')}>
                                        <OrderBy isClicked={this.state.sortBy === 'famille'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Type
                                    <span onClick={() => this.sort('type')}>
                                        <OrderBy isClicked={this.state.sortBy === 'type'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Durée
                                    <span onClick={() => this.sort('duree')}>
                                        <OrderBy isClicked={this.state.sortBy === 'duree'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">
                                    Fin
                                    <span onClick={() => this.sort('dateFin')}>
                                        <OrderBy isClicked={this.state.sortBy === 'dateFin'}/>
                                    </span>
                                </th>
                                <th className="sortStyle">État</th>
                                <th className="sortStyle">
                                    Capacité
                                </th>
                                <th colSpan="2" className="sortStyle">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="container-fluid py-3 px-lg-5">
                    <div className="row d-flex justify-content-between">
                        <AjoutNouveau etat="Libre"/>
                        <Pagination
                            ref          = {this.paginationRef}
                            url          = '/locations/locationsByEspace/'
                            eSpaceId     = {localStorage.getItem('espace_id')}
                            type         = {null}
                            sort         = {this.state.sortBy}
                            order        = {this.state.orderBy}
                            filters      = {this.props.filters}
                            search       = {this.state.search}
                            updateState  = {this.updateState}
                        />
                        <div
                            className="list-button d-flex justify-content-center justify-content-sm-end align-items-center mt-4 mt-sm-0">
                            <div>
                                <input type="search" onKeyUp={(e) => {this.setState((prevState) => {
                                    return {
                                        ...prevState,
                                        search: e.target.value
                                    }
                                })}} placeholder="Recherche" className={"form-control"} />
                            </div>
                            <div>
                            <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('location')}>
                                <img src={pdfIconTbl} alt="" className="img-fluid"/>
                                <span className="soleilBold-fonts">EXPORTER LISTE <br/> CONCESSIONS</span>
                            </button>

                                <button type="button" className="export-locations-list-btn d-flex cursor" onClick={() => GetListePDF('location', null, 'csv')}>
                                    <img src={filterIconSubmenu} alt="" className="img-fluid"/>
                                    <span className="soleilBold-fonts">EXPORTER EXCEL <br/> CONCESSIONS</span>
                                </button>
                            </div>
                            <div>
                                <select name="" className="form-control mb-2" id="" value={this.state.typeExt} onChange={(e) => {
                                this.state.typeExt = e.target.value
                                }}>
                                    <option value="pdf">PDF</option>
                                    <option value="csv">Excel</option>
                                </select>
                            <button type="button" className="listing-complete-btn soleilBold-fonts cursor" onClick={() => GetListePDF('location',  this.state.selectedId, this.state.typeExt)}>
                                Exporter la sélection
                                <span className="slt-nb">{ this.state.selected }</span></button>
                            </div>
                            <button type="button" className="listing-complete-btn soleilBold-fonts">Liste filtrée ( {this.state.total}
                                )
                            </button>
                            <button type="button" className="listing-incomplete-btn soleilBold-fonts">Liste complète (
                                {this.state.total} )
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConcessionsListe;