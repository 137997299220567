import redBellIcon from '../../assets/images/red-bell-icon.svg';
import greenTickIcon from '../../assets/images/green-tick-icon.svg';
import redTickIcon from '../../assets/images/red-cross-icon.svg';
import timerIcon from '../../assets/images/timer-icon.svg';
import pdfAddedIcon from '../../assets/images/pdf-added-icon.svg';
import warningIcon from '../../assets/images/warning-icon.svg';
import React, { Component } from 'react';
import Tarifs from "./Tarifs";
import axios from "axios";
import {Modal} from "react-bootstrap";
import Activation from "../../components/gestionCompte/Activation";
import user from "../../helpers/user";

class InfosCimetiere extends Component{

    constructor(props) {
        super(props);
        this.state= {
            espaceId       : null,
            nom            : '',
            adresse        : '',
            codePostal     : '',
            ville          : '',
            horaires       : [],
            reglement      : '',
            patrimoine     : '',
            documents      : [],
            autresDocuments: [],
            show           : false,
            nomDocument    : '',
            fileDocument   : null,
            actif          : null,
            uid            : '',
            import         : null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.espace && this.props.espace.id !== this.state.espaceId) {
            const espace = this.props.espace
            this.setState({
                espaceId       : espace.id,
                nom            : espace.nom,
                adresse        : espace.adresse,
                codePostal     : espace.codePostal,
                ville          : espace.ville,
                horaires       : espace.horaires ? espace.horaires : [],
                reglement      : espace.nomReglement,
                patrimoine     : espace.nomPatrimoine,
                autresDocuments: espace.autresDocuments,
                documents      : espace.autresDocumentsArray,
                actif          : espace.actif,
                uid            : espace.uid
            })
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name] : e.target.value})
    }

    handleHoraires = (e) => {
        const index = e.target.getAttribute('data-index')
        let horaires = this.state.horaires
        horaires[index] = e.target.value
        this.setState({
            horaires : horaires
        })
    }

    updateHoraires = () => {
        axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
            horaires : this.state.horaires
        })
    }

    updateInfosCimetiere = (e) => {
        axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
            [e.target.name] : e.target.value
        })
    }

    uploadFile(e, state) {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        axios.post("/documents/upload/documents", formData, {
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
                [state]: process.env.REACT_APP_API_URI + '/documents/' + res.data.id
            }).then(() => {
                this.setState({[state]: res.data.nom})
                e.target.value = null
            })
        })
    }
    resetSVG(e) {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        axios.post("/ESpaces/front_import_svg?espaceId="+ localStorage.getItem('espace_id'), formData, {
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            this.setState({'import': 'ok'})
        })
    }
    resetExcel(e) {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        axios.post("/ESpaces/front_import_excel?espaceId="+ localStorage.getItem('espace_id'), formData, {
            maxContentLength: 100000000,
            maxBodyLength: 1000000000,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            this.setState({'import': 'ok'})
        })
    }

    supprimerDocSimple = (state) => {
        axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
            [state]: null
        })
        this.setState({[state]: null})
    }

    handleAutreDocument = (e) => {
        this.setState({fileDocument : e})
    }

    uploadAutreDocument = () => {
        let formData = new FormData()
        formData.append('file', this.state.fileDocument.target.files[0])
        this.setState({show: false})
        axios.post("/documents/upload/documents/" + this.state.nomDocument, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            const documents = this.state.documents
            documents.push({
                'id'         : res.data.id,
                'nom'        : res.data.nom,
                'description': res.data.description
            })
            const autresDocuments = this.state.autresDocuments
            autresDocuments.push(process.env.REACT_APP_API_URI + '/documents/' + res.data.id)
            axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
                autresDocuments : autresDocuments
            }).then((res) => {
                this.setState({
                    nomDocument     : '',
                    fileDocument    : null,
                    autresDocuments : res.data.autresDocuments
                })
            })
        })
    }

    supprimerDocument = (doc) => {
        const documents = this.state.documents
        if(documents.indexOf(doc) >= 0) {
            documents.splice(documents.indexOf(doc), 1)
        }
        const autresDocuments = this.state.autresDocuments
        const index = autresDocuments.indexOf(process.env.REACT_APP_API_URI + '/documents/' + doc.id)
        if (index) {
            autresDocuments.splice(index, 1)
        }
        axios.put(process.env.REACT_APP_API_URL + '/e_spaces/' + localStorage.getItem('espace_id'), {
            autresDocuments : autresDocuments
        })
        this.setState({
            documents : documents,
            autresDocuments: autresDocuments
        })
    }

    render() {
        const jours = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
        const horaires = jours.map( (jour,index) => {
            return (
                <div className="cols" key={index}>
                    <label>{jour}</label>
                    <textarea className="horaire-mairie" placeholder="non renseigné" value={this.state.horaires[index] ? this.state.horaires[index] : ''} data-index={index} onChange={this.handleHoraires} onBlur={this.updateHoraires}/>
                </div>
            )
        })

        const autresDocuments = this.state.documents.map(doc => {
            return (
                <div className="mb-3">
                    <div className="soleilRegular-fonts text-1 px-3">{doc.description}</div>
                    <div className="d-flex mt-1 px-3">
                        <div className="pdf-name soleilBold-fonts mb-2">
                            <img src={pdfAddedIcon} alt="" className="img-fluid"/>
                            {doc.nom}
                        </div>
                        <div className="d-flex">
                            <a href={process.env.REACT_APP_DOCS_URL + doc.nom}
                               className="download-btn soleilRegular-fonts px-3 py-1 mb-2"
                               target="_blank">
                                Télécharger
                            </a>
                            <button type="button" className="delete-btn soleilRegular-fonts m-0 p-0" onClick={() => this.supprimerDocument(doc)}>Supprimer</button>
                        </div>
                    </div>
                </div>
            )
        })
        let myuser = null
        if (localStorage.getItem(user.USER_LOCAL_KEY)) {
            myuser = JSON.parse(localStorage.getItem(user.USER_LOCAL_KEY))
        }
        return(
            <div>
                <div className="header px-3 px-lg-5 py-2 soleilBold-fonts">
                    <img src={redBellIcon} alt="" className="img-fluid mr-2"/>
                    {this.state.nom}
                    {
                        this.state.actif !== null ? <Activation actif={this.state.actif}/> : ''
                    }
                </div>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-wrapper mt-3">
                                <div className="d-flex">
                                    <label className="col-4 soleilRegular-fonts">Nom cimetière</label>
                                    <input type="text" className="col-8 soleilRegular-fonts" name="nom" value={this.state.nom} onChange={this.handleChange} onBlur={this.updateInfosCimetiere}/>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="col-4 soleilRegular-fonts">Adresse</label>
                                    <input type="text" className="col-8 soleilRegular-fonts" name="adresse" value={this.state.adresse} onChange={this.handleChange} onBlur={this.updateInfosCimetiere}/>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="col-4 soleilRegular-fonts">Code postal</label>
                                    <input type="text" className="col-8 soleilRegular-fonts" name="codePostal" value={this.state.codePostal} onChange={this.handleChange} onBlur={this.updateInfosCimetiere}/>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="col-4 soleilRegular-fonts">Ville</label>
                                    <input type="text" className="col-8 soleilRegular-fonts" name="ville" value={this.state.ville} onChange={this.handleChange} onBlur={this.updateInfosCimetiere}/>
                                </div>
                                <div className="d-flex mt-2">
                                    <label className="col-4 soleilRegular-fonts">Lien portail citoyen </label>
                                    <input type="text" className="col-8 soleilRegular-fonts" name="uid" value={this.state.uid} onChange={this.handleChange} onBlur={this.updateInfosCimetiere}/>
                                    <label htmlFor="" className="col-4"></label>
                                    <a className={"col-8"} href={`https://portail.logiplace.fr/cimetiere/` + this.state.uid} target="_blank">{`https://portail.logiplace.fr/cimetiere/` + this.state.uid}</a>

                                </div>
                            </div>
                            <div className="timer-wrapper mt-3">
                                <div className="title soleilBold-fonts">
                                    <img src={timerIcon} alt="" className="img-fluid mr-3"/> HORAIRES CIMETIÈRE
                                </div>
                                <div className="d-flex">
                                    {horaires}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="pdf-wrapper mt-3">
                                <div className="soleilRegular-fonts text-1 px-3">
                                    <img src={this.state.reglement ? greenTickIcon : redTickIcon } alt="" className="img-fluid mr-2"/>
                                    Règlement du cimetière
                                </div>
                                <div className="d-flex mt-2 px-3 align-items-center">
                                    {
                                        this.state.reglement ?
                                            <>
                                                <div className="pdf-name soleilBold-fonts">
                                                    <img src={pdfAddedIcon} alt="" className="img-fluid"/>
                                                    {this.state.reglement}
                                                </div>
                                                <div>
                                                    <a href={process.env.REACT_APP_DOCS_URL + this.state.reglement}
                                                       className="download-btn soleilRegular-fonts p-2"
                                                       target="_blank">Télécharger</a>
                                                </div>
                                            </>
                                            : <p className="grey small px-3">Aucun règlement ajouté</p>
                                    }
                                    <div className="p-2">
                                        <label htmlFor="modif-reglement" className="modify-btn soleilRegular-fonts m-0 p-0 mr-3">{this.state.reglement ? 'Modifier': 'Ajouter un règlement'}</label>
                                        <input id="modif-reglement" className="d-none" type="file" onChange={(e) => this.uploadFile(e, 'reglement')}/>
                                        {
                                            this.state.reglement ?
                                                <button type="button" className="delete-btn soleilRegular-fonts m-0 p-0" onClick={() => this.supprimerDocSimple('reglement')}>Supprimer</button>
                                                : ''
                                        }
                                    </div>
                                </div>
                                <input type="file" id="pdf-file-select" accept="application/pdf"
                                       className="d-none"
                                       onChange={(e) => this.uploadFile(e, 'patrimoine')}/>
                                {
                                    !this.state.patrimoine ?
                                        <div className="warning-wrapper mt-5">
                                            <div className="title">
                                                <img src={warningIcon} alt="" className="img-fluid"/>
                                                <span className="soleilRegular-fonts">Vous avez du patrimoine funéraire ?
                                            Ajouter le document présentant le patrimoine de votre cimetière (pdf)</span>
                                            </div>
                                            <div className="browse-file mt-3 align-items-center">
                                                {
                                                    !this.state.patrimoine ?
                                                        <label htmlFor="pdf-file-select"
                                                               className="soleilRegular-fonts mr-3">
                                                            Parcourir ...
                                                        </label>
                                                        : ''
                                                }
                                                <div
                                                    className="soleilRegular-fonts">{this.state.patrimoine ? this.state.patrimoine : 'Aucun fichier PDF sélectionné'}</div>
                                            </div>
                                        </div>
                                        :
                                        <div className="mt-3">
                                            <div className="soleilRegular-fonts text-1 px-3">
                                                <img src={greenTickIcon} alt="" className="img-fluid mr-2"/>
                                                Patrimoine funéraire
                                            </div>
                                            <div className="d-flex mt-2 px-3 align-items-center">
                                                <div className="pdf-name soleilBold-fonts">
                                                    <img src={pdfAddedIcon} alt="" className="img-fluid"/>
                                                    {this.state.patrimoine}
                                                </div>
                                                <div>
                                                    <a href={process.env.REACT_APP_DOCS_URL + this.state.patrimoine}
                                                       className="download-btn soleilRegular-fonts p-2"
                                                       target="_blank">Télécharger</a>
                                                </div>
                                                <div className="p-2">
                                                    <label htmlFor="pdf-file-select" className="modify-btn soleilRegular-fonts m-0 p-0 mr-3">Modifier</label>
                                                    <button type="button" className="delete-btn soleilRegular-fonts m-0 p-0" onClick={() => this.supprimerDocSimple('patrimoine')}>Supprimer</button>
                                                </div>
                                            </div>
                                        </div>
                                }
                                <hr/>
                                {autresDocuments}
                                <button type="button" className="ml-3 add-doc-btn soleilRegular-fonts mt-2"
                                        onClick={() => {this.setState({show : true})}}>
                                    Ajouter un document
                                </button>
                            </div>

                            { myuser && (myuser.roles.includes('ROLE_SUPER_ADMIN') || myuser.roles.includes('ROLE_ADMIN')) ?
                                <div className="pdf-wrapper mt-3">
                                    <div className="soleilRegular-fonts text-1 px-3">
                                        Mise à jour du SVG
                                    </div>
                                    {
                                        !this.state.import ?
                                    <div className="d-flex mt-2 px-3 align-items-center">
                                        <input type="file" id="svg-file-select" accept="image/svg+xml"
                                               className="d-none"
                                               onChange={(e) => this.resetSVG(e)}/>
                                        <div className="warning-wrapper mt-5">
                                            <div className="title">
                                                <span className="soleilRegular-fonts">Attention de faire une sauvegarde avant de faire la mise à jour ... </span>
                                            </div>
                                            <div className="browse-file mt-3 align-items-center">
                                                    <label htmlFor="svg-file-select"
                                                           className="soleilRegular-fonts mr-3">
                                                        Parcourir ...
                                                    </label>
                                            </div>
                                        </div>
                                    </div>
                                            : 'Import réalisé'
                                    }
                                </div>
                                : <></>
                            }

                            { myuser && (myuser.roles.includes('ROLE_SUPER_ADMIN') || myuser.roles.includes('ROLE_ADMIN')) ?
                                <div className="pdf-wrapper mt-3">
                                    <div className="soleilRegular-fonts text-1 px-3">
                                        Mise à jour du Fichier Excel
                                    </div>
                                    {
                                        !this.state.import ?
                                            <div className="d-flex mt-2 px-3 align-items-center">
                                                <input type="file" id="excel-file-select" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                       className="d-none"
                                                       onChange={(e) => this.resetExcel(e)}/>
                                                <div className="warning-wrapper mt-5">
                                                    <div className="title">
                                                        <span className="soleilRegular-fonts">Attention de faire une sauvegarde avant de faire la mise à jour ... </span>
                                                    </div>
                                                    <div className="browse-file mt-3 align-items-center">
                                                        <label htmlFor="excel-file-select"
                                                               className="soleilRegular-fonts mr-3">
                                                            Parcourir ...
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            : 'Import réalisé'
                                    }
                                </div>
                                : <></>
                            }
                        </div>
                        <Tarifs/>
                    </div>
                </div>
                <Modal show={this.state.show} onHide={() => {this.setState({show : false})}}>
                    <Modal.Header className="bk-grey" closeButton/>
                    <Modal.Body>
                        <p className="soleilBold-fonts title purple m-2">Ajouter un document</p>
                        <div className="form-wrapper mt-1">
                            <div className="d-flex">
                                <label className="col-12 soleilRegular-fonts m-0 p-0">Nom du document</label>
                                <input type="text" className="col-12 soleilRegular-fonts" name="nomDocument" value={this.state.nomDocument} onChange={this.handleChange}/>
                            </div>
                            <div className="mt-4">
                                <div className="d-flex align-items-center dl-input-wrapper upload-wrapper">
                                    <label htmlFor="autre-document" className="add-doc-btn dl-actes white mr-2">
                                        Télécharger
                                    </label>
                                    <label className="soleilRegular-fonts m-0">{this.state.fileDocument ? this.state.fileDocument.target.files[0].name : 'Aucun fichier sélectionné'}</label>
                                    <input id="autre-document" type="file" className="d-none" onChange={this.handleAutreDocument}/>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="red cancel-btn tbl" onClick={() => {this.setState({show : false})}}>
                            Annuler
                        </button>
                        <button type="button" className="tbl bk-red ml-2" onClick={this.uploadAutreDocument}>
                            Ajouter
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default InfosCimetiere;