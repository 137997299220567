import React, {Component} from "react";
import notif_purple from "../../assets/images/notif-info-icon-purple.svg";
import notif_check_vert from "../../assets/images/notif-check-vert.svg";
import notif_check_gris from '../../assets/images/notif-check-gris.svg';
import close_cross from "../../assets/images/close-cross.svg";
import timer from "../../assets/images/notif-timer.svg";
import axios from "axios";
import back from "../../assets/images/back-icon-purple.svg";
import {Link} from "react-router-dom";
import SuggestionsForm from "../gestionCompte/SuggestionsForm";

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications : [],
            espace: localStorage.getItem('espace_id')
        }
    }

    componentDidMount () {
        this.getNotifications()
        setInterval(() => this.getNotifications(), 60000);
    }

    getNotifications() {
        axios.get(process.env.REACT_APP_ES + 'logicim_' + this.state.espace + '_notifications/_search', {
            withCredentials: false,
            headers: {
                "Access-Control-Allow-Origin" : "*",
            },
            params : {
                source : JSON.stringify({
                    from: 0,
                    size: 50,
                    sort: [
                        { dateRecu: { order: "desc" }},
                        { id: { order: "desc" }}
                    ],
                    query: {
                        bool: {
                            must:[
                                {
                                    match: {
                                        archive: false
                                    }
                                }
                            ]
                        }
                    }
                }),
                source_content_type : 'application/json'
            }
        }).then((res) => {
            const hits = res.data.hits.hits
            const notifs = hits.map(hit => {
                let notif = hit['_source']
                notif['_id'] = hit['_id']
                return notif
            })
            this.calculNbNotifNonLues(notifs)
            this.setState({notifications: notifs})
        }).catch(() => {
            //console.log(e)
        })
    }

    calculNbNotifNonLues = (notifs) => {
        const nonLues = notifs.filter(notif => !notif.dateLue)
        this.props.setNbNotif(nonLues)
    }

    fermerNotif = () => {
        this.props.fermerNotif()
    }

    calculJour = (date) => {
        const date1      = new Date(date)
        const date2      = new Date()
        const difference = date2.getTime() - date1.getTime()
        const days       = Math.floor(difference / (1000 * 3600 * 24))
        if (days === 0)
            return 'aujourd\'hui'
        else if (days === 1)
            return '1 jour'
        else
            return days + ' jours'
    }

    archiveNotif = (notif) => {
        notif.archive = true
        const id = notif['_id']
        delete notif['_id']
        let notifications = this.state.notifications
        notifications.splice(notifications.indexOf(notif), 1)
        this.setState({notifications : notifications})
        axios.put(process.env.REACT_APP_ES + 'logicim_' + this.state.espace + '_notifications/_doc/' + id, notif).catch(() => {})
    }

    nePlusRappeler = (notification) => {
        axios.post(process.env.REACT_APP_API_URL + '/notifications/remove-notification', {
                eSpaceId     : localStorage.getItem('espace_id'),
                notification : notification
        }).catch(() => {})
    }

    render() {
        const notifications = this.state.notifications.map((notif, index) => {
            return (
                <div className="notif d-flex" key={index}>
                    <div className="col-2 d-flex flex-column align-items-center px-0">
                        <img src={notif_purple} className="img-fluid" width="30px" alt=""/>
                        <p className="soleilLight-fonts mb-4 date">{notif.dateRecu ? this.calculJour(notif.dateRecu)  : ''}</p>
                        <img src={notif.dateLue ? notif_check_vert : notif_check_gris} className="img-fluid" width="30px" alt=""/>
                    </div>
                    <div className=" col-8 notif-content px-0">
                        <p className="soleilBold-fonts mb-1">{notif.titre}</p>
                        <p className="soleilRegular-fonts mb-0">{notif.description}</p>
                        {
                            notif.titre === 'Donnez nous votre avis' ? <SuggestionsForm/> : ''
                        }
                        {
                            notif.emplacementId ?
                            <Link
                                to={{ pathname: "/fiche-concession/" + notif.emplacementId}}
                                className="red soleilLight-fonts mt-1 float-right" style={{fontSize : '15px'}}
                            >
                                Consulter la concession
                            </Link>
                                : ''
                        }
                        {
                            notif.titre === 'Paramétrage du compte' ?
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <a
                                        style={{fontSize : '14px'}}
                                        className="cursor"
                                        onClick={() => this.nePlusRappeler(notif.criticite)}
                                    >
                                        ne plus me le rappeler
                                    </a>
                                    <Link
                                        to={{pathname: "/gestion-compte"}}
                                        className="red soleilLight-fonts mt-1"
                                        style={{fontSize : '15px'}}
                                    >
                                        Paramétrer
                                    </Link>
                                </div>
                                : ''
                        }
                    </div>
                    <div
                        className=" col-2 d-flex flex-column justify-content-between align-items-center">
                        <img src={close_cross} className="img-fluid cursor" width="20px" onClick={() => this.archiveNotif(notif)}
                             alt=""/>
                        <img src={timer} className="img-fluid mb-2 d-none" width="30px" alt=""/>
                    </div>
                </div>
            )
        })
        return (
            <div id="sidePanelNotifications" className="sidepanel">
                <img src={back} className="closebtn" onClick={this.fermerNotif} alt=""/>
                <div className="d-flex flex-column ">
                    {notifications}
                </div>
            </div>
        )
    }
}

export default Notifications;