import DateTools from "../utils/DateTools";

export default function AffichageExclus (location) {
    const type = location.typeConcession
    const exclus = location.exclus && Object.keys(location.exclus).length > 0  ? location.exclus : null
    const arrayExclu = []
    if(exclus) {
        for (let i in exclus) {
            arrayExclu.push("<li>" + exclus[i].nom + " " + exclus[i].prenom + " né(e) le " + (exclus[i].dateDeNaissance ? DateTools.formatDate(exclus[i].dateDeNaissance) : '-')  + "</li>")
        }
    }
    switch (type) {
        case 'Familiale' :
            return "<div>Personnes pouvant être inhumées : " +
                        "<ul>" +
                            "<li>Le concessionnaire et son conjoint</li>" +
                            "<li>Les ascendants et descendants du concessionnaire (et leurs conjoints)</li>" +
                            "<li>Les alliés du concessionnaire</li>" +
                            "<li>Les enfants adoptifs du concessionnaire</li>" +
                            "<li>Une personne étrangère à la famille avec laquelle le concessionnaire avait un lien affectif et de reconnaissance</li>" +
                        "</ul>" +
                   "</div>" +
                   (exclus ? "<div> Personne(s) exclues par le concessionnaire : " + "<ul>" + arrayExclu  + "</ul>" + "</div>" : "")
        case 'Collective' :
            return "<div>" +
                      (exclus ? "<div> Personnes pouvant être inhumées selon le concessionnaire : " + "<ul>" + arrayExclu  + "</ul>" + "</div>" : "Sans effet pour cette concession") +
                  "</div>"
        case 'Individuelle' :
            return "<div>" +
                (exclus ? "<div> Personne pouvant être inhumée selon le concessionnaire : " + "<ul>" + arrayExclu  + "</ul>" + "</div>" : "Sans effet pour cette concession") +
                  "</div>"
        default :
            return "<div>Sans effet pour cette concession" +
             "</div>"
    }
}