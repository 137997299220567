import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import Header from './components/header/Header';
import Sidebar from './components/sidebar/Sidebar';
import './styles/App.scss';
import './assets/css/main.css';
import './assets/css/navigation.css';
import {BrowserRouter as Router} from "react-router-dom";
import Routing from './router.js';
import { connect } from "react-redux";
import {login} from "./actions/"
import userHelper from "./helpers/user"
import axios from "./helpers/axios"
import React from 'react'

window.$ = $;
require('bootstrap/dist/js/bootstrap.js');

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentWillUnmount() {
    }

    componentWillMount() {


        const currentUser = userHelper.getUser()

        axios.interceptors.request.use((config) => {
            if (config.url.indexOf("/elastic/") !== -1) {
                config.headers.Authorization = null;
                config.withCredentials = false;
            }
            this.setState({
                loading: true
            })
            return config
        }, (error) => {
            this.setState({
                loading: false
            })
            return Promise.reject(error)
        });

        axios.interceptors.response.use( (response) => {
            this.setState({
                loading: false
            })

            return response;
        },  (error) => {
            this.setState({
                loading: false
            })
            return Promise.reject(error);
        })

        if (currentUser) {
            axios.get(process.env.REACT_APP_API_URL + "/users/currentUser").then(() => {
            }).catch(() => {
                userHelper.logout()
            })
        }
    }

    render ()
    {
        return (
            <Router>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
                <div className={`App  ${this.state.loading === true ? "loading" : ""}`}>
                    <Header/>
                    <Sidebar/>
                    <Routing/>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = ({ data = {}, isLoadingData = false }) => ({
    data,
    isLoadingData
});
export default connect(
    mapStateToProps,
    {
        login
    }
)(App);
