import React, { Component } from 'react';
import axios from "../../helpers/axios";
import $ from "jquery";
import {connect} from "react-redux";

class FormEmplacement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emplacementId :  this.props.emplacementId,
            formEmplacement: [],
            surface: null
        }
    }

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/emplacements/emplacement-form/' + this.state.emplacementId )
            .then((response) => {
                this.setState({formEmplacement: response.data})
                $(function () {
                    $('[data-toggle="popover"]').popover()
                })
                this.gestionChampsSurface()
            })
    }

    gestionChampsSurface() {
        const emplacementChampsSurface = document.getElementById('emplacement_surface')
        const wrapperAutreSurface = document.getElementById('autre-surface-emplacement')
        const champsAutreSurface = document.getElementById('emplacement_surfaceAutre')
        if(emplacementChampsSurface && emplacementChampsSurface.value && emplacementChampsSurface.value !== '11')
            this.setState({surface: emplacementChampsSurface.value})
        else if (champsAutreSurface && champsAutreSurface.value)
            this.setState({surface : champsAutreSurface.value})
        if(emplacementChampsSurface) {
            emplacementChampsSurface.addEventListener('change', (e) => {
                document.getElementById('attribution_emplacement_tarifs').value = '0'
                if (e.target.value !== '11') {
                    this.setState({surface: e.target.value})
                    wrapperAutreSurface.classList.add('display-none')
                } else {
                    wrapperAutreSurface.classList.remove('display-none')
                }
            })
        }

        const emplacementChampsAutreSurface = document.getElementById('emplacement_surfaceAutre')
        if(emplacementChampsAutreSurface) {
            emplacementChampsAutreSurface.addEventListener('change', (e) => {
                this.setState({surface: parseFloat(e.target.value.replace(',', '.')).toFixed(2)})
            })
        }
    }

    sendFormulaire = () => {
        const form = $('#formEmplacement')
        let postDatas = form.find('form').serialize();
        postDatas += '&surface=' + this.state.surface
        axios.post(form.data('submit'), postDatas).then((response) => {
            const {updateEmplacement} = this.props
            updateEmplacement(response.data)
        });
    }

    render() {
        require('../../assets/css/formPop-up.css')
        return (
            <div className="contents content-margin">
                <div className="modal fade procedure-modal modal-large" id="modif-emplacement" tabIndex="-1" role="dialog"
                     aria-labelledby="modif-emplacement" aria-hidden="true">
                    <div className="modal-dialog  d-flex justify-content-center align-items-center modal-wrap"
                         role="document">
                        <div className="modal-content">
                            <div className="modal-header bk-grey">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-body-top">
                                    <p className="soleilBold-fonts title purple">modification de l'emplacement</p>
                                </div>
                                <div className="form-wrapper scroll-popup">
                                    <div dangerouslySetInnerHTML={{__html:this.state.formEmplacement}}
                                         data-submit={process.env.REACT_APP_API_URL + '/emplacements/emplacement-form/'+ this.state.emplacementId}
                                         id='formEmplacement'>
                                    </div>
                                    <div className="modal-footer navigation mt-5 p-0">
                                        <div/>
                                        <div>
                                            <button className="red cancel-btn tbl" data-dismiss="modal">Annuler</button>
                                            <button type="submit" data-dismiss="modal" className="tbl bk-red ml-2" onClick={this.sendFormulaire} id="form-emplacement-submit">Valider</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateEmplacement : (emplacement) => dispatch({
            type : 'UPDATE_EMPLACEMENT',
            emplacement : emplacement
        })
    }
}

const mapStateToProps = ({ defunt = null, defuntListe = {}, defuntAjoute = 0,defuntAjouteId = null }) => ({
    defunt,
    defuntListe,
    defuntAjoute,
    defuntAjouteId
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormEmplacement);